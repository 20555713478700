module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Far Beyond', // Navigation and Site Title
  titleAlt: 'Far Beyond', // Title for JSONLD
  description:
    'Far Beyond is an independent creative and innovation company with a focus on building audience-led strategies, stories and experiences for brands.',
  banner: '/far-beyond-logo.png',
  headline:
    'Far Beyond collaborates closely with its clients on brand creation.', // Headline for schema.org JSONLD
  url: 'https://farbeyond.io', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  // logo: '/src/images/svg/far-beyond-logo.svg', // Used for SEO
  ogLanguage: 'en_GB', // Facebook Language

  // JSONLD / Manifest
  // favicon: '/src/images/far-beyond-favicon.png', // Used for manifest favicon generation
  shortName: 'Far Beyond', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Jack Kemmish', // Author for schemaORGJSONLD
  themeColor: '#56a03f',
  backgroundColor: '#56a03f',

  twitter: '', // Twitter Username
  facebook: '', // Facebook Site Name
  googleAnalyticsID: '',
}
